export const updateState = (oldState, updatedProperties) => ({
  ...oldState,
  ...updatedProperties,
});

export const checkISODate = str =>
  // eslint-disable-next-line max-len
  /^[0-9]{4}-((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01])|(0[469]|11)-(0[1-9]|[12][0-9]|30)|(02)-(0[1-9]|[12][0-9]))T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])\.[0-9]{3}Z$/.test(
    str,
  );

export const getFirstCharOfStrings = str =>
  str
    ? str
      .match(/\b(\w)/g)
      .join('')
      .toUpperCase()
    : '';

export const validateEmail = (email) => {
  if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(email)) {
    return true;
  } return false;
};

export const validateMobileNo = (mobileNumber) => {
  if (/^[0-9]{10}$/.test(mobileNumber)) {
    return true;
  } return false;
};

export const removeHtmlTags = (str) => {
  return str.replace(/<[^>]*>/g, '');
};
